module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-minimal-portfolio-blog","short_name":"gmpb","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/felfele-logo.png","localize":[{"start_url":"/niche","lang":"niche","name":"niche","short_name":"niche","display":"minimal-ui","background_color":"#141033","theme_color":"#34DFB6","icon":"src/assets/images/niche-icon.png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a6b38e5dfa3aaa1dea3b742b13940541","cacheDigests":{"/niche":"b618000bb6b27f3ee50c5361d6e4db71"}},
    }]
